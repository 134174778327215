import { useEffect } from 'react';
import { ErrorBoundaryType } from '@/types';
import { IEventTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web';

export type ErrorType = Error & { digest?: string };
type ExceptionProperties = Partial<IExceptionTelemetry['exception']> & { enabled?: boolean };
type EventProperties = Partial<IEventTelemetry['properties']> & { enabled?: boolean };

const useTrackException = (
  error: ErrorType,
  exceptionProperties?: ExceptionProperties,
  eventProperties?: EventProperties
) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);

    if (window.appInsights) {
      const { enabled: exceptionEnabled = true, ...exceptionPropertiesRest } = exceptionProperties ?? {};
      const exception = {
        message: error.stack ?? '',
        url: window.location.href,
        lineNumber: 0,
        columnNumber: 0,
        error,
        typeName: ErrorBoundaryType.Generic,
        ...exceptionPropertiesRest,
      };
      if (exceptionEnabled) {
        window.appInsights.trackException({
          error,
          exception,
        });
      }

      const { enabled: eventEnabled = true, ...eventPropertiesRest } = eventProperties ?? {};
      const properties = {
        errorType: ErrorBoundaryType.Generic,
        url: window.location.href,
        message: error.stack,
        errorMessageReferenceKey: ErrorBoundaryType.Generic || 'UNKNOWN',
        ...eventPropertiesRest,
      };
      if (eventEnabled) {
        window.appInsights.trackEvent({
          name: 'ERROR_BOUNDARY',
          properties,
        });
      }
    }
  }, [error, exceptionProperties, eventProperties]);
};

export default useTrackException;
